const registerGoogleMap = () => {
	// Declare map
	const googleMap = document.querySelectorAll('#map');

	// Google Maps
	googleMap.forEach((element) => {
		function createMap(el, lat, long, zoom, marker) {
			// Set a center point of the map, can be re-evaluated using `centerMap()`
			const center = { lat: lat, lng: long };

			const mapOptions = {
				zoom: zoom,
				center: center,
				fullscreenControl: false,
			};

			// Create a map instance.
			const melonMap = new MelonMap(document.getElementById(el), mapOptions);

			// Marker locations [name, lat, long, zindex]
			const markerLocation = [['Active Design', lat, long, 1]];

			if (marker == true) {
				markerLocation.forEach((markerLocation) => {
					const title = markerLocation[0];

					const location = {
						lat: parseFloat(markerLocation[1]),
						lng: parseFloat(markerLocation[2]),
					}

					melonMap.addMarker(location, title);
				});
			}

			// Add a map style
			melonMap.setMapStyle(
				[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
				{ name: 'Styled Map' }
			);
		}

		// Initiate google map for contact form
		createMap('map', 52.51608802823704, -1.8757919737972903, 10, true);
	});
}

document.addEventListener('DOMContentLoaded', () => {
	const $ = window.jQuery;

	registerGoogleMap();

	const body = document.querySelector('body');

	// Helper function
	let domReady = (cb) => {
		document.readyState === 'interactive' || document.readyState === 'complete'
			? cb()
			: document.addEventListener('DOMContentLoaded', cb);
	};

	domReady(() => {
		// Display body when DOM is loaded
		body.style.visibility = 'visible';
	});

	// Google Recaptcha v3
	grecaptcha.ready(function () {
		document.querySelectorAll('form.mod-recaptcha').forEach((element) => {
			grecaptcha.execute('6Lf7_8UpAAAAAAVBU6lAe3Tm-o9cPAD3CYIp1wjF', { action: 'submit' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(element)
			});
		});
	});

	// hide cookie policy
	document.querySelector('.section-cookies .btn-accept').addEventListener('click', (e) => {
		e.preventDefault();

		// If no validation errors
		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 1,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	document.querySelector('.section-cookies .btn-decline').addEventListener('click', (e) => {
		e.preventDefault();

		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 0,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	// change aria label to inputs when clicked on add effect
	const form = document.querySelectorAll('form');

	if (form) {
		document.querySelectorAll('form input, form textarea').forEach((element) => {
			element.addEventListener('blur', () => {
				if (element.value) {
					element.setAttribute('aria-invalid', 'false');
				} else {
					element.setAttribute('aria-invalid', 'true');
				}
			});
		});
	}

	const contactPage = document.querySelectorAll('#contact'),
		contactForm = document.querySelector('#contactForm');

	if (contactPage) {

		if (contactForm) {
			// Ajax contact form processing
			contactForm.addEventListener('submit', function (event) {
				event.preventDefault();

				const spamField = document.querySelectorAll('input#Form_Email'),
					loadingSpinner = document.querySelectorAll('.section-form .loading');

				// if honeypot field is filled in by spambot, do not submit
				if (spamField[0].value) {
					return false;
				}

				loadingSpinner[0].classList.add('mod-show');

				$.ajax({
					method: 'POST',
					url: 'process/contact/',
					dataType: 'json',
					data: $(this).serialize()
				}).done(function (response) {
					// If no errors
					if (response == 'success') {
						loadingSpinner[0].classList.remove('mod-show');

						// Remove filled class on inputs
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-filled');
						});

						// Hide form temporarily
						contactForm.classList.add('mod-hide');

						// Show thank you message
						contactForm.nextElementSibling.classList.add('mod-show');

						// Remove any errors that may still be there
						contactForm.firstElementChild.innerHTML = '';

						// Remove any values in inputs that would be still in there after submission
						document.querySelectorAll('#contactForm .form-element input, #contactForm .form-element textarea').forEach((element) => {
							element.value = '';
						});

						// Empty all error texts
						document.querySelectorAll('#contact .sub-error').forEach((element) => {
							element.innerHTML = '';
						});

						// Remove error class
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-error');
						});

						// Hide thank you message after 10 seconds and return to form
						setTimeout(function () {
							contactForm.classList.remove('mod-hide');
							contactForm.nextElementSibling.classList.remove('mod-show');
						}, 10000);

					}
					else {
						loadingSpinner[0].classList.remove('mod-show');

						contactForm.firstElementChild.innerHTML = 'Please fix the errors below:';

						// Empty any error messages that don't need it
						document.querySelectorAll('#contactForm .sub-error').forEach((element) => {
							element.innerHTML = '';
						});

						// Remove error class
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-error');
						});

						for (const [key, value] of Object.entries(response)) {
							if (key) {
								// Implement error message to specific input that needs it
								document.querySelectorAll('#contactForm .sub-error[data-error="' + key + '"]')[0].innerHTML = response[key]['message'];

								// Add error class to form element for styling
								if (key !== 'recaptcha') {
									document.querySelectorAll('#contactForm .form-element.mod-' + key)[0].classList.add('mod-error');
								}
							}
						}
					}
				});
			});
		}
	}

	// Pops out share modal window
	document.querySelectorAll('.social-share').forEach((element) => {
		element.addEventListener('click', (e) => {

			// Prevents default action
			e.preventDefault();
			// Runs window popup function
			windowPopup($(this).attr("href"), 500, 300);
		});
	});


	// Function for popout window
	function windowPopup(url, width, height) {
		// Calculate the position for the popup so it’s centered on the screen.
		const left = (screen.width / 2) - (width / 2);
		const top = (screen.height / 2) - (height / 2);

		// opens a window with the given url & set position from above
		window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
	}

	if (document.querySelector('.js-tabs')) {
		// Tabs
		const tabs = document.querySelectorAll('.js-tabs');

		if (tabs) {
			document.querySelectorAll('.js-tabs').forEach((element) => {
				var productPage = document.getElementById("product");

				if (productPage) {
					var tabLabels = document.querySelectorAll(".product-tabs button");
					var tabPanes = document.getElementsByClassName("tab-content");
				} else {
					// Define variables
					var tabLabels = element.firstElementChild.querySelectorAll('button');

					if (element.nextElementSibling) {
						var tabPanes = element.nextElementSibling.querySelectorAll('.tab-content');
					}
				}

				function activateTab(e) {
					e.preventDefault();

					// Deactivate all tabs
					tabLabels.forEach(function (label, index) {
						label.classList.remove('mod-active');
						label.setAttribute('aria-selected', 'false');
						label.setAttribute('tabindex', '-1');
					});

					if (tabPanes) {
						[].forEach.call(tabPanes, function (pane, index) {
							pane.classList.remove('mod-active');
						});
					}

					// Activate current tab
					e.target.classList.add('mod-active');
					e.target.setAttribute('aria-selected', 'true');
					e.target.setAttribute('tabindex', '0');

					var clickedTab = e.target.getAttribute("href");
					var clickedTab = clickedTab.split('#');
					var clickedTab = clickedTab[1];
					var buttonText = e.target.innerText;

					if (clickedTab) {
						var backBtn = document.querySelectorAll('.nav-back.mod_top');

						document.getElementById(clickedTab).classList.add('mod-active');

						if (!productPage) {
							if (document.querySelectorAll('.js-tabs-mobile')) {
								document.querySelectorAll('.js-tabs-mobile')[0].classList.add('mod-disabled');
							}

							if (document.querySelectorAll('.section-forms-and-downloads.mod-mobile .sub-content')) {
								document.querySelectorAll('.section-forms-and-downloads.mod-mobile .sub-content')[0].classList.add('mod-show');
							}

							document.querySelectorAll('.js-title')[0].innerText = buttonText;

							backBtn.forEach((element) => {
								element.addEventListener('click', () => {
									document.querySelectorAll('.section-forms-and-downloads.mod-mobile .sub-content')[0].classList.remove('mod-show');
									document.querySelectorAll('.js-tabs-mobile')[0].classList.remove('mod-disabled');
								});
							});
						}
					}
				}

				// Apply event listeners
				tabLabels.forEach(function (label, index) {
					label.addEventListener("click", activateTab);
				});
			});

			var formsDropdown = document.querySelectorAll('#sub_categories');

			formsDropdown.forEach((element) => {

				element.addEventListener('change', (e) => {
					e.target.classList.add('mod-hide');
					e.target.previousElementSibling.style.display = 'none';

					document.querySelectorAll('.js-title')[0].style.display = 'none';
					document.querySelectorAll('.nav-back.mod_top')[0].classList.add('mod-hide');
					document.querySelectorAll('.nav-back.mod_second')[0].classList.remove('mod-hide');

					var fileWrap = document.querySelectorAll('.files_mobile.mod-show');

					for (var i = 0; i < fileWrap.length; i++) {
						// Remove active class from content div
						fileWrap[i].classList.remove('mod-show');
					}

					document.querySelectorAll('.section-forms-and-downloads.mod-mobile #' + e.target.value)[0].classList.add('mod-show');

					e.target.selectedIndex = 0;

				});
			});

			var secondaryBackBtn = document.querySelectorAll('.nav-back.mod_second');

			secondaryBackBtn.forEach((element) => {
				element.addEventListener('click', () => {
					element.classList.add('mod-hide');
					document.querySelectorAll('.select-dropdown .arrow')[0].style.display = 'block';
					document.querySelectorAll('.nav-back.mod_top')[0].classList.remove('mod-hide');
					document.querySelectorAll('.js-title')[0].style.display = 'block';
					document.querySelectorAll('#sub_categories')[0].classList.remove('mod-hide');

					var fileWrap = document.querySelectorAll('.files_mobile.mod-show');

					for (var i = 0; i < fileWrap.length; i++) {
						// Remove active class from content div
						fileWrap[i].classList.remove('mod-show');
					}
				});
			});



		}
	}

	// Accordion
	const accordion = document.querySelectorAll('#accordion');

	if (accordion) {
		// Define variables
		var accordionLabels = document.querySelectorAll("#accordion .accordion-item");

		// Listen for click on the document
		function activateAccordion(event) {
			// If the Tab key is hit or a mouse click occurs then run the code
			if (event.type == 'click' || event.keyCode == '9') {
				// Bail if our clicked element doesn't have the class
				if (!event.target.classList.contains('item-accordion')) return;

				// Get the target content
				var content = document.querySelector(event.target.getAttribute('data-link'));
				if (!content) return;

				// Prevent default link behavior
				event.preventDefault();

				// Add active class to accordian item link
				event.target.classList.add('active');
				event.target.setAttribute('aria-expanded', 'true');

				// If the content is already expanded, collapse it and quit
				if (content.classList.contains('active')) {
					content.classList.remove('active');
					event.target.classList.remove('active');
					event.target.setAttribute('aria-expanded', 'false');
					return;
				}

				// Get all open accordion content, loop through it, and close it
				var accordions = document.querySelectorAll('.accordion-content.active');
				for (var i = 0; i < accordions.length; i++) {
					// Remove active class from content div
					accordions[i].classList.remove('active');

					// Remove active class from accordion item link
					accordions[i].previousElementSibling.children[0].classList.remove('active');
					accordions[i].previousElementSibling.children[0].setAttribute('aria-expanded', 'false');
				}

				// Toggle our content
				content.classList.toggle('active');
			}
		};

		// Apply event listeners
		accordionLabels.forEach(function (label, index) {
			label.addEventListener("click", activateAccordion);
			label.addEventListener("keyup", activateAccordion);
		});
	}

	// Read more functionality
	const readMoreBtn = document.querySelectorAll('.js-read-more-btn');

	if (readMoreBtn) {
		readMoreBtn.forEach((element) => {
			element.addEventListener('click', () => {
				const hiddenContent = element.previousElementSibling;

				if (hiddenContent.classList.contains('mod-hidden-content-visible') === true) {
					hiddenContent.classList.remove('mod-hidden-content-visible');
					element.innerHTML = 'Read More';
					element.nextElementSibling.classList.remove('mod-show');

					return;
				}

				hiddenContent.classList.add('mod-hidden-content-visible');
				element.innerHTML = 'Read Less';
				element.nextElementSibling.classList.add('mod-show');

			});
		});
	}

	// Hamburger menu function
	document.querySelectorAll('.mob-hamburger').forEach((hamburger) => {
		hamburger.addEventListener('click', (e) => {
			e.preventDefault();

			// Add active class to hamburger so it can be animated
			hamburger.classList.toggle('mod-active');

			hamburger.setAttribute('aria-label', 'Close menu');

			// Add active class to nav to show
			document.querySelector('#nav').classList.toggle('mod-show');

			// Add no scroll class to body to prevent scrolling when nav is active
			body.classList.toggle('no-scroll');

			// Add active class to header
			document.querySelector('.header-main').classList.toggle('mod-active');

			// Add active class on all li's
			if (!hamburger.classList.contains('mod-active')) {
				hamburger.setAttribute('aria-label', 'Open menu');

				document.querySelectorAll('#nav ul li').forEach((element) => {
					element.classList.remove('mod-show');
				});

				setTimeout(function () {
					document.querySelector('#nav').classList.remove('mod-show');
				}, 550);
			} else {
				setTimeout(function () {
					document.querySelectorAll('#nav ul li').forEach((element) => {
						element.classList.add('mod-show');
					});
				}, 900);
			}
		});
	});


	// Circle feature on product image for mobile
	document.querySelectorAll('.poi-circle').forEach((circle) => {
		circle.addEventListener('click', (e) => {
			e.preventDefault();

			// Add active class to circle so it can be active
			circle.classList.add('mod-active');

			setTimeout(function () {
				circle.classList.remove('mod-active');
			}, 3500);
		});
	});


	// SLICK JS BITS

	if ($('.cards.js-slick').length) {
		$('.cards.js-slick').slick({
			accessibility: true,
			arrows: true,
			autoplay: false,
			draggable: false,
			dots: false,
			infinite: false,
			slidesToScroll: 3,
			slidesToShow: 3,
			speed: 400,
			prevArrow: $('.nav-slider .prev'),
			nextArrow: $('.nav-slider .next'),
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToScroll: 2,
						slidesToShow: 2,
						draggable: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToScroll: 1,
						slidesToShow: 1,
						draggable: true
					}
				}
			]
		});
	}

	if ($('#news-post').length) {
		const newsStoryCarousel = $('.carousel-images-wrap');

		newsStoryCarousel.slick({
			autoplay: true,
			autoplaySpeed: 5000,
			dots: true,
			arrows: false
		});

		const slideCount = newsStoryCarousel.find('.slick-slide:not(.slick-cloned)').length;
		const dotWidth = 100 / slideCount;
		const dots = newsStoryCarousel.find('.slick-dots li.slick-active');

		// Set with of each li so that they equally fill up the carousel's width (e.g. 3 slides would have 33% width).
		dots.css('width', dotWidth + '%');

		// Create clones that will always be visible (and grey).
		dots.find('button').each(function () {
			const clonedDot = $(this).clone();

			clonedDot.addClass('js-clone');

			$(this).parent().append(clonedDot);
		});

		// Iterate over each (green) non-clone and animate its width from 0% to 100%.
		function animateButton(i, slideCount, delay) {
			const button = dots.eq(i).find('button:not(.js-clone)');

			button.animate({ width: '100%' }, delay, () => {
				button.css('width', 0);

				newsStoryCarousel.slick('slickGoTo', i, false);
			});

			++i;

			if (i === slideCount) {
				i = 0;
			}

			setTimeout(() => animateButton(i, slideCount, delay), delay);
		}

		// Pass all relevant data to the function itself to keep it pure (rather than relying on global state).
		animateButton(0, slideCount, 5000);
	}

	document.querySelectorAll('.scroll-to').forEach((element) => {
		element.addEventListener('click', (e) => {
			e.preventDefault();

			var link = element.hash;
			var targetScroll = $(link).offset().top;

			$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');
		});
	});

	// Careers

	const $department = $('#department');
	const $location = $('#location');
	const $jobs = $('.job-list .job');

	function updateList() {
		const currentDepartment = $department.val();
		const currentLocation = $location.val();

		$jobs.each(function() {
			$(this).show();

			if (currentLocation !== '' && currentLocation !== $(this).data('location')) {
				$(this).hide();
			}

			if (currentDepartment !== '' && currentDepartment !== $(this).data('department')) {
				$(this).hide();
			}
		})
	}

	const myLocations = [];
	const myDepartments = [];

	$('.job-list li').click(function() {
		const $this = $(this);

		$('.job-list li').each((index, li) => {
			const $li = $(li);

			if ($li.is($this)) {
				return;
			}

			if ($li.hasClass('mod-active')) {
				$li.removeClass('mod-active');
				$li.find('.details').slideToggle();
			}
		});

		this.classList.toggle('mod-active');
		$this.find('.details').slideToggle();
	});

	$jobs.each(function() {
		const thisLocation = $(this).data('location');
		if ($.inArray(thisLocation, myLocations) === -1) {
			myLocations.push($(this).data('location'));
			$location.append(new Option($(this).data('location'), $(this).data('location')));
		}

		const thisDepartment = $(this).data('department');
		if ($.inArray(thisDepartment, myDepartments) === -1) {
			myDepartments.push($(this).data('department'));
			$department.append(new Option($(this).data('department'), $(this).data('department')));
		}
	})

	$department.change(function() {
		updateList($);
	})

	$location.change(function() {
		updateList($);
	});
});
